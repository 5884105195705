import React from 'react';
import PropertyCard from './PropertyCard';
import PageSelect from 'components/PageSelect';
import { useRouter } from 'next/router';
import { getRoute } from 'utils/languages';
import { LanguageKey } from 'types/common';
import { FindPublicPropertiesResponse } from '../../schemas/properties/PublicPropertyDTO';

export type PropertiesGridProps = {
  columns: number;
  isClientSide?: boolean;
} & FindPublicPropertiesResponse;

const PropertiesGrid = ({ data, columns, pagination: { total, page, pageSize }, isClientSide = false }: PropertiesGridProps) => {
  const router = useRouter();

  const gotoPage = (page: number) => {
    const { pathname, locale, query } = router;
    const { page: _, ...restQuery } = query;
    const newQuery = page === 1 ? restQuery : { ...query, page };
    router.push({ pathname: getRoute(pathname, locale as LanguageKey), query: newQuery }, undefined, { shallow: isClientSide });
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="mb-14 flex w-full justify-center md:justify-start">
        <div className={`grid grid-cols-1 gap-7 md:grid-cols-${columns} w-full`}>
          {data.map((property) => (
            <PropertyCard key={property.id} property={property} />
          ))}
        </div>
      </div>
      {total > pageSize && (
        <PageSelect page={page} totalResults={total} pageSize={pageSize} setPage={gotoPage} SEO={{ canonicalPath: router.asPath.split('?')[0] }} />
      )}
    </>
  );
};

export default PropertiesGrid;
